<template>
  <div id="index" ref="appRef" style="padding: 0 40px">
    <!-- <audio ref="audio" :src="music_path" >
    </audio> -->

    <div class="bg">
      <div class="host-body">
        <!-- <dv-loading v-if="loading">Loading...</dv-loading> -->
        <div>
          <div class="d-flex jc-center" style="margin-top: 70px">
            <dv-decoration-10 class="dv-dec-10" />
            <div class="d-flex jc-center">
              <dv-decoration-8
                class="dv-dec-8"
                :color="['#568aea', '#000000']"
              />
              <div class="title">
                <span class="title-text">物流配送跟踪系统</span>
                <dv-decoration-6
                  class="dv-dec-6"
                  :reverse="true"
                  :color="['#50e3c2', '#67a1e5']"
                />
              </div>
              <dv-decoration-8
                class="dv-dec-8"
                :reverse="true"
                :color="['#568aea', '#000000']"
              />
            </div>
            <dv-decoration-10 class="dv-dec-10-s" />
          </div>
          <div class="d-flex jc-between px-2">
            <div class="d-flex aside-width"></div>
            <div class="d-flex aside-width">
              <div class="react-right mr-4 react-l-s">
                <span class="react-after"></span>
                <span class="text"
                  >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div >
          <div style="width:25%;display:inline-block;">
            <dv-scroll-board
              :config="config"
              style="
                width: 100%;
                height: 820px;
                margin-top: 20px;
                padding: 20px;
              "
            />
          </div>

          <div style="width:50%;display:inline-block;">
            <div
              class="content-box"
              style="

              "
            >
              <dv-loading v-if="loading">Loading...</dv-loading>
              <div v-else style="width:33%;display:inline-block;">
                <dv-decoration-9
                  :dur="order_data.no_dispose_order.dur"
                  style="
                    width: 240px;
                    height: 240px;
                    text-align: center;
                    font-size: 30px;
                     margin: 0 auto
                  "
                >
                  待装车<br /><br />
                  {{ order_data.no_dispose_order.num }}单
                </dv-decoration-9>
              </div>
              <dv-loading v-if="loading">Loading...</dv-loading>
              <div v-else style="width:33%;display:inline-block;">
                <dv-decoration-9
                  :dur="order_data.dispose_order.dur"
                  style="
                    width: 240px;
                    height: 240px;
                    text-align: center;
                    font-size: 30px;
                     margin: 0 auto
                  "
                >
                  已出发<br /><br />
                  {{ order_data.dispose_order.num }}单
                </dv-decoration-9>
              </div>
              <dv-loading v-if="loading">Loading...</dv-loading>
              <div v-else style="width:33%;display:inline-block;">
                <dv-decoration-9
                  :dur="order_data.all_order.dur"
                  style="
                    width: 240px;
                    height: 240px;
                    text-align: center;
                    font-size: 30px;
                     margin: 0 auto
                  "
                >
                  已完成<br /><br />{{ order_data.all_order.num }}单
                </dv-decoration-9>
              </div>
            </div>
            <div style="height: 600px; margin-top: 20px" id="container"></div>
          </div>
          <div style="width:25%;display:inline-block;">
            <dv-scroll-board
              :config="fc_config"
              style="
                width: 100%;
                height: 820px;
                margin-top: 20px;
                padding: 20px;
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from "../utils/index.js";
import Speech from "speak-tts";
import MapLoader from "@/assets/js/AMap.js";
export default {
  mixins: [drawMixin],
  data() {
    return {
      order_data: {},
      music_path: "https://imgs.xinfucai.cn/Static/audio/xindingdan.mp3",
      config: {
        headerHeight: 100,
        header: ["<span style='font-size:30px;'>已装车</span>"],
        data: [],
        // index: true,
        // columnWidth: [150],
        align: ["center"],
        rowNum: 10,
        carousel: "single",
        waitTime: 4000,
      },
      fc_config: {
        headerHeight: 100,
        header: ["<span style='font-size:30px;'>配送中</span>"],
        data: [],
        // index: true,
        // columnWidth: [150],
        align: ["center"],
        rowNum: 10,
        carousel: "single",
        waitTime: 4000,
      },
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      websock: null,
      cdata: {},
      is_play: false,
      map: null,
      dataTree: [],
    };
  },
  components: {},
  mounted() {
    this.timeFn();
    this.cancelLoading();
  },
  created() {
    this.initWebSocket();
  },
  destroyed() {
    this.websock.close(); //离开路由之后断开websocket连接
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    // 获取高德地图api
    mapReq(vehicle_longitude_latitude, order_longitude_latitude) {
      let that = this;
      this.dataTree = order_longitude_latitude;
      MapLoader().then(
        (AMap) => {
          console.log("地图加载成功");
          let positionX = 0;
          let positionY = 0;
          if (this.dataTree.length > 0) {
            for (let i = 0; i < this.dataTree.length; i++) {
              positionX =
                positionX + parseFloat(this.dataTree[i].split(",")[0]);
              positionY =
                positionY + parseFloat(this.dataTree[i].split(",")[1]);
            }
            positionX=parseFloat(positionX / this.dataTree.length)
            positionY=parseFloat(positionY / this.dataTree.length)
          } else if (vehicle_longitude_latitude.length > 0) {
            for (let i = 0; i < vehicle_longitude_latitude.length; i++) {
              positionX =
                positionX +
                parseFloat(
                  vehicle_longitude_latitude[i].vehicle_no_lng_lat.split(",")[0]
                );
              positionY =
                positionY +
                parseFloat(
                  vehicle_longitude_latitude[i].vehicle_no_lng_lat.split(",")[1]
                );

            }
            positionX=parseFloat(positionX / vehicle_longitude_latitude.length)
            positionY=parseFloat(positionY / vehicle_longitude_latitude.length)
          }
          that.map = new AMap.Map("container", {
            resizeEnable: true,
            center: [
              positionX,
              positionY,
            ], //地图标记title
            zoom: 12.5, //地图视图缩放级别
          });
          // this.map.setMapStyle('amap://styles/light');
          if (this.dataTree.length > 0) {
            for (let i = 0; i < this.dataTree.length; i++) {
              let marker = new AMap.Marker({
                position: [
                  this.dataTree[i].split(",")[0],
                  this.dataTree[i].split(",")[1],
                ],
                icon: new AMap.Icon({
                  image: require("@/assets/mark/mark_b" + (i + 1) + ".png"), //定位点显示的图标
                }),
                offset: new AMap.Pixel(-30, -60), //图标点的位置
              });
              marker.setMap(that.map);
            }
          }
          // if (vehicle_longitude_latitude.length > 0) {
          //   for (let i = 0; i < vehicle_longitude_latitude.length; i++) {
          //     let marker = new AMap.Marker({
          //       position: [
          //         vehicle_longitude_latitude[i].split(",")[0],
          //         vehicle_longitude_latitude[i].split(",")[1],
          //       ],
          //       icon: new AMap.Icon({
          //         image: require("@/assets/mark/cheliang.png"), //定位点显示的图标
          //       }),
          //       offset: new AMap.Pixel(-30, -60), //图标点的位置
          //     });
          //     marker.setMap(that.map);
          //     marker.setLabel({
          //       //label默认蓝框白底左上角显示，样式className为：amap-marker-label
          //       offset: new AMap.Pixel(36, 0), //修改label相对于maker的位置
          //       content: 343434,
          //     });
          //   }
          // }
          if (vehicle_longitude_latitude.length > 0) {
            for (let i = 0; i < vehicle_longitude_latitude.length; i++) {
              console.log(
                vehicle_longitude_latitude[i].vehicle_no_lng_lat.split(",")[0]
              );
              let marker = new AMap.Marker({
                position: [
                  vehicle_longitude_latitude[i].vehicle_no_lng_lat.split(
                    ","
                  )[0],
                  vehicle_longitude_latitude[i].vehicle_no_lng_lat.split(
                    ","
                  )[1],
                ],
                icon: new AMap.Icon({
                  image: require("@/assets/mark/cheliang.png"), //定位点显示的图标
                }),
                offset: new AMap.Pixel(-30, -60), //图标点的位置
              });
              marker.setMap(that.map);
              marker.setLabel({
                //label默认蓝框白底左上角显示，样式className为：amap-marker-label
                offset: new AMap.Pixel(36, 0), //修改label相对于maker的位置
                content: vehicle_longitude_latitude[i].vehicle_no,
              });
            }
          }
        },
        (e) => {
          console.log("地图加载失败", e);
        }
      );
    },
    playStart() {
      this.is_play = true;
      this.$refs.audio.src = this.music_path;
      this.$refs["audio"].play();
    },
    playStop() {
      this.is_play = false;
    },
    //语音播报
    reloadYP() {
      this.$refs.audio.src = this.music_path;
      if (this.is_play) {
        //   this.$refs['audio'].pause();
        //   this.is_play = false
        //   this.buttonText = 'stopped'
        // } else {
        this.$refs["audio"].play();
        // this.is_play = true
        // this.buttonText = 'starting'
      }
    },

    speechInit() {
      this.speech = new Speech();
      this.speech.setLanguage("zh-CN");
      this.speech.init().then(() => {
        console.log("语音播报初始化完成...");
      });
    },

    //  语音播报
    speak(text) {
      console.log(text);
      console.log(this.speech);
      this.speech.speak({ text: text }).then(() => {
        console.log("播报完成...");
      });
    },

    //语音播报结束

    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },

    initWebSocket() {
      //初始化weosocket
      // const wsuri = "ws://127.0.0.1:9502";
      // const wsuri = "wss://ws.hzsp668.com";
      //  const wsuri = "wss://ws.hfx.xinfucai.cn";
      // const wsuri = "wss://ws.kaida668.com";
      const wsuri = "wss://ws.jiaqifucai.cn";
      // const wsuri = "ws://cxjc.ws.hzsp668.com";
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      // let region_id = "138_peisong";
      // let region_id = "52_peisong";
      // let region_id = "138_peisong";
      // let region_id = "343_peisong";
      let region_id = "140_peisong";
      // let region_id = "163_peisong";

      let original_data = {
        class: "Login",
        action: "loginDp",
        content: {
          region_id,
        },
      };
      this.websocketsend(JSON.stringify(original_data));
      //请求数据
      // let get_data = {
      //   class: "Login",
      //   action: "loginDp",
      //   content: {
      //     region_id,
      //   },
      // };
      //  this.websocketsend(JSON.stringify(get_data));
    },
    websocketonerror() {
      //连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) {
      //数据接收
      const redata = JSON.parse(e.data);
      console.log(redata);
      let config = this.config;
      this.order_data = redata.peisong_data;

      config = {
        headerHeight: 100,
        header: ["<span style='font-size:30px;'>已装车</span>"],
        data: redata.left_order_list,
        align: ["center"],
        rowNum: 7,
        carousel: "single",
        waitTime: 4000,
      };
      let fc_config = {
        headerHeight: 100,
        header: ["<span style='font-size:30px;'>配送中</span>"],
        data: redata.right_order_list,
        align: ["center"],
        rowNum: 7,
        carousel: "single",
        waitTime: 4000,
      };

      this.config = config;
      this.fc_config = fc_config;
      let vehicle_longitude_latitude = redata.vehicle_longitude_latitude;
      let order_longitude_latitude = redata.order_longitude_latitude;

      this.mapReq(vehicle_longitude_latitude, order_longitude_latitude);
      //  if(redata.type=='yuyin'){
      //     this.reloadYP();
      //   }
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      console.log("断开连接", e);
      this.initWebSocket();
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/index.scss";
.dv-scroll-board .rows .row-item {
  line-height: normal !important;
  height: auto !important;
  padding: 10px;
}
.dv-scroll-board .rows .row-item {
  line-height: normal !important;
  height: auto !important;
  padding: 10px;
}
.amap-marker-label {
  border: 1px solid #eee;
  color: #fff;
  background: #DEB887;
}
</style>
